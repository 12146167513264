import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["questionnaire", "questions", "questionsWrapper"]
    static values = { companyId: Number, translation: Object}
    connect() {
        console.log("Report controller connected");
        console.log("Company ID Value:", this.companyIdValue);
        console.log("Questions target present:", this.hasQuestionsTarget);
        if (!this.hasQuestionsTarget) {
            console.warn("No target element 'questions' found for 'report' controller");
            return;
        }
    }

    // async updateQuestionsForSelectedQuestionnaires(event) {
    //     const selectedOptions = Array.from(event.target.selectedOptions);
    //     const questionnaireIds = selectedOptions.map(option => option.value);
    //
    //     if (questionnaireIds.length === 0) {
    //         this.updateQuestions([]);
    //         return;
    //     }
    //
    //     try {
    //         const response = await fetch(`/companies/${this.companyIdValue}/reports/questions?questionnaire_ids=${questionnaireIds.join(",")}`);
    //         if (!response.ok) {
    //             throw new Error(`HTTP error! status: ${response.status}`);
    //         }
    //         const data = await response.json();
    //         console.log(data)
    //         this.updateQuestions(data);
    //     } catch (error) {
    //         console.error("Error fetching questions:", error);
    //         alert("There was an error fetching the questions. Please check your input or try again later.");
    //     }
    // }

    updateQuestions(questions) {
        if (!this.hasQuestionsTarget) {
            console.error("Missing target element 'questions' for 'report' controller");
            return;
        }

        this.questionsTarget.innerHTML = "";

        if (questions.length === 0) {
            const option = document.createElement("option");
            option.textContent = this.translationValue.select_prompt;
            option.disabled = true;
            option.selected = true;
            this.questionsTarget.appendChild(option);
            return;
        }

        questions.forEach((question) => {
            const option = document.createElement("option");
            option.value = question.id;
            option.textContent = question.statement;
            this.questionsTarget.appendChild(option);
        });
    }

    async updateQuestionsForSelectedQuestionnaires(event) {
        const select = event.currentTarget
        const selectedIds = Array.from(select.selectedOptions)
            .map(o => o.value)
            .join(",")

        if (!selectedIds) {
            this.questionsTarget.innerHTML = ""
            this.questionsWrapperTarget.style.display = "none"
            return
        }

        try {
            const response = await fetch(`/companies/${this.companyIdValue}/reports/questions?questionnaire_ids=${selectedIds}`)
            const data = await response.json()

            let html = ""
            if (data.length > 0) {
                data.forEach(question => {
                    html += `
            <div class="form-check">
              <input type="checkbox"
                     class="form-check-input"
                     id="question_${question.id}"
                     name="q[question_id_in][]"
                     value="${question.id}">
              <label class="form-check-label" for="question_${question.id}">${question.statement}</label>
            </div>
          `
                })
                this.questionsWrapperTarget.style.display = "block"
            } else {
                this.questionsWrapperTarget.style.display = "none"
            }
            this.questionsTarget.innerHTML = html
        } catch (error) {
            console.error(error)
        }
    }

    selectAllQuestions() {
        this._setAllQuestionCheckboxes(true)
    }

    deselectAllQuestions() {
        this._setAllQuestionCheckboxes(false)
    }

    _setAllQuestionCheckboxes(checked) {
        const checkboxes = this.questionsTarget.querySelectorAll('input[type="checkbox"]')
        checkboxes.forEach(cb => {
            cb.checked = checked
        })
    }

    get reportCompanyIdValue() {
        return this.element.dataset.reportCompanyIdValue
    }


}