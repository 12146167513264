import {Controller} from "@hotwired/stimulus"
import {getAreaOfPolygon, getCenter, getDistance} from "geolib"
import mapbox from "mapbox-gl"
import * as turf from "@turf/turf"

// import MapboxDraw from "@mapbox/mapbox-gl-draw"
// import {FetchRequest} from "@rails/request.js";

// Connects to data-controller="maps"
export default class extends Controller {
  static values = {
    bounds: Array,
    inspection: Object,
    token: String,
    plotCenterImage: String,
    mapCenter: {
      type: Object,
      default: {
        lat: {type: Number, default: 0.1},
        lng: {type: Number, default: 0.1}
      }
    },
    ein: Array,
    polygons: {
      type: Array,
      default: []
    },
    productiveSpaceId: Number
  }

  static targets = ["map", "productiveSpace", "inspection", "nonCompliance", "productiveSpaceItems"]

  connect() {
    mapbox.accessToken = this.tokenValue
    console.log('Maps Controller')
    super.connect()
  }

  disconnect() {
    // this.map.remove()
    super.disconnect()
  }

  mapTargetConnected(element) {
    mapbox.accessToken = this.tokenValue
    this.loadNonComplianceAnswerMap({params: {mapElementId: element.id}})
  }

  productiveSpaceTargetConnected(element) {
    mapbox.accessToken = this.tokenValue
    this.loadProductiveSpaceMap({params: {mapElementId: element.id}})
  }

  productiveSpaceItemsTargetConnected(element) {
    mapbox.accessToken = this.tokenValue
    this.loadProductiveSpaceMap({params: {mapElementId: element.id}})
    this.drawItems(this.einValue)
  }

  nonComplianceTargetConnected(element) {
    mapbox.accessToken = this.tokenValue
    this.loadNonComplianceAnswerMap({params: {mapElementId: element.id}})
  }

  inspectionTargetConnected(element) {
    mapbox.accessToken = this.tokenValue
    this.loadMap({params: {mapElementId: element.id}})
  }

  exportWaypointKml() {
    if (this.inspectionValue?.waypoints?.length > 0) {
      const groupedWaypoints = {}
      const keys = []

      for (const point of this.inspectionValue?.waypoints) {
        if (!groupedWaypoints[point.location_model_realm_id]) {
          keys.push(point.location_model_realm_id)
          groupedWaypoints[point.location_model_realm_id] = []
        }

        const lastPoint = groupedWaypoints[point.location_model_realm_id][groupedWaypoints[point.location_model_realm_id].length -1]

        if (!lastPoint || lastPoint.longitude !== point.longitude || lastPoint.latitude !== point.latitude) {
          groupedWaypoints[point.location_model_realm_id].push(point)
        }
      }

      let kml = `<?xml version="1.0" encoding="UTF-8"?><kml xmlns="http://www.opengis.net/kml/2.2"><Document>`
      for (const key of keys) {
        const waypoints = groupedWaypoints[key]
        let name = ""

        if (waypoints[0].location_model_name === "Entity") {
          name = `${this.inspectionValue.embedded_entity.name ?? ""} ${this.inspectionValue.embedded_entity.surname ?? ""}`
        } else if (waypoints[0].location_model_name === "ProductiveSpace") {
          const ps = this.inspectionValue.embedded_productive_units.flatMap( pu => pu.embedded_productive_spaces).find( ps => ps.realm_id == waypoints[0].location_model_realm_id)
          name = `[${ps?.code ?? ""}] ${ps?.name}`
        } else {
          const pu = this.inspectionValue.embedded_productive_units.find( pu => pu.realm_id == waypoints[0].location_model_realm_id)
          name = pu?.name
        }

        kml += `<Placemark>
                  <name>${name}</name>
                  <Polygon>
                    <outerBoundaryIs>
                      <LinearRing>
                        <coordinates>`;
            waypoints?.forEach(coord => {
              kml += `${coord.longitude},${coord.latitude},${coord.altitude} `;
            });
            kml += `</coordinates>
                      </LinearRing>
                    </outerBoundaryIs>
                  </Polygon>
            </Placemark>`;
      }

      kml += `</Document></kml>`
      this.saveKml(kml, "waypoints")
    }
  }

  exportToKml(event) {
    const productiveSpaces = this.inspectionValue?.embedded_productive_units?.flatMap(pu => pu.embedded_productive_spaces)
    const productiveSpace = productiveSpaces.find(ps => ps.realm_id === event.target.value)

    if (productiveSpace) {
      let entrance_coords = ''

      if (productiveSpace.entrance_point) {
        entrance_coords = `${productiveSpace.entrance_point.longitude},${productiveSpace.entrance_point.latitude},${productiveSpace.entrance_point.altitude ?? 0}`
      }

      let kml = `<?xml version="1.0" encoding="UTF-8"?>
        <kml xmlns="http://www.opengis.net/kml/2.2">`
      kml += `<Document>
              <name>${this.inspectionValue.embedded_entity.name ?? ""} ${this.inspectionValue.embedded_entity.surname ?? ""}</name>
              <ExtendedData>
                <Data name="ProducerName">
                  <value>${this.inspectionValue.embedded_entity.name ?? ""} ${this.inspectionValue.embedded_entity.surname ?? ""} </value>
                </Data>
                <Data name="ProductionPlaceName">
                  <value>${productiveSpace.name ?? ""}</value>
                </Data> 
                <Data name="PlotID">
                  <value>${productiveSpace.code ?? ""}</value>
                </Data>
                <Data name="ProductionPlaceRealmId">
                  <value>${productiveSpace.realm_id}</value>
                </Data>
                <Data name="ProductionPlaceArea">
                  <value>${productiveSpace.area}</value>
                </Data>
              </ExtendedData>`;

      if (!productiveSpace.bounds || productiveSpace.bounds.length === 0) {
        kml += `<Placemark>
                        <name>${this.inspectionValue.embedded_entity.name ?? ""} ${this.inspectionValue.embedded_entity.surname ?? ""} - Point</name>
                        <ExtendedData>
                           <Data name="ProducerName">
                              <value>${this.inspectionValue.embedded_entity.name ?? ""} ${this.inspectionValue.embedded_entity.surname ?? ""}</value>
                           </Data>
                           <Data name="ProductionPlaceName">
                             <value> ${productiveSpace.name ?? ""} </value>
                           </Data>
                          <Data name="PlotID">
                            <value>${productiveSpace.code ?? ""}</value>
                          </Data>
                          <Data name="ProductionPlaceRealmId">
                            <value>${productiveSpace.realm_id}</value>
                          </Data>
       
                          <Data name="ProductionPlaceArea">
                            <value>${productiveSpace.area}</value>
                          </Data>
                        </ExtendedData>
                        <Point>
                          <coordinates>
                            ${entrance_coords}
                          </coordinates>
                        </Point>
                      </Placemark>`
      } else {
        kml += `<Placemark>
                        <name>${this.inspectionValue.embedded_entity.name ?? ""} ${this.inspectionValue.embedded_entity.surname ?? ""} - Polygon</name>
                         <ExtendedData>
                          <Data name="ProducerName">
                            <value>${this.inspectionValue.embedded_entity.name ?? ""} ${this.inspectionValue.embedded_entity.surname ?? ""}</value>
                          </Data>
                         <Data name="ProductionPlaceName">
                           <value>${productiveSpace.name ?? ""}</value>
                         </Data>
                         <Data name="ProductionPlaceArea">
                          <value>${productiveSpace.area}</value>
                        </Data>
                         <Data name="PlotID">
                          <value>${productiveSpace.code ?? ""}</value>
                        </Data>
                        <Data name="ProductionPlaceRealmId">
                          <value>${productiveSpace.realm_id}</value>
                        </Data>
   
                        </ExtendedData>
                        <Polygon>
                          <outerBoundaryIs>
                            <LinearRing>
                              <coordinates>`;
        productiveSpace.bounds?.forEach(coord => {
          kml += `${coord.longitude},${coord.latitude},${coord.altitude ?? 0} `;
        });
        kml += `</coordinates>
                            </LinearRing>
                          </outerBoundaryIs>
                        </Polygon>
                      </Placemark>`;
      }

      kml += `</Document>
              </kml>`;

      this.saveKml(kml, `${productiveSpace.code ?? ""}_${productiveSpace.name}`)
    }

    // Multiple poructiveSpaces
    // if (productiveSpaces.length > 0) {
    //
    //   let kml = `<?xml version="1.0" encoding="UTF-8"?><kml xmlns="http://www.opengis.net/kml/2.2"><Document>`
    //
    //   for (const ps of productiveSpaces) {
    //     kml += `<Placemark><name>${ps.name}</name>
    //       <Polygon>
    //         <outerBoundaryIs>
    //           <LinearRing>
    //             <coordinates>`;
    //               ps.bounds?.forEach(coord => {
    //                 kml += `${coord.longitude},${coord.latitude},${coord.altitude} `;
    //               });
    //         kml += `</coordinates>
    //           </LinearRing>
    //         </outerBoundaryIs>
    //       </Polygon>
    //     </Placemark>`;
    //   }
    //
    //   kml += `</Document></kml>`
    //   this.saveKml(kml)
    // }
  }

  saveKml(kml, name) {
    const blob = new Blob([kml], {type: 'application/vnd.google-earth.kml+xml'})
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `${this.inspectionValue.embedded_entity?.name ?? ''}${this.inspectionValue.embedded_entity?.surname ? " " + this.inspectionValue.embedded_entity?.surname : ""}_${name}.kml`;

    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    window.URL.revokeObjectURL(link.href)
  }


  loadMap(event) {
    const {mapElementId} = event.params

    if (mapElementId) {
      const {lat, lng} = this.mapCenterValue

      this.map = new mapbox.Map({
        container: mapElementId,
        style: "mapbox://styles/mapbox/satellite-streets-v12",
        zoom: 16,
        center: [lng, lat]
      })

      this.map.addControl(new mapbox.NavigationControl())

      this.map.on("load", () => {
        this.addCenterOfProductiveSpace()
        this.drawPolygons()
        this.drawItems(this.einValue)
        this.drawWaypoints()
      })

      // this.map.on("idle", (e) => {
      //   console.log({e})
      //   this.drawItems(this.einValue)
      // })
    }
  }

  addCenterOfProductiveSpace() {
    if (!this.inspectionValue || !this.plotCenterImageValue) {
      return;
    }

    const plots = this.inspectionValue.embedded_productive_units.flatMap(pu => pu.embedded_productive_spaces).filter(a => !!a)

    for (const plot of plots) {
      new mapbox.Marker({color: '#F5C100'})
      .setLngLat([plot.entrance_point?.longitude || 0, plot.entrance_point?.latitude || 0])
      .addTo(this.map)
    }


    // Roman cambio de opinion ya no quiere la imagen
    // this.map.loadImage(this.plotCenterImageValue, (err, image) => {
    //   if (err) return;
    //
    //   this.map.addImage('plot-center-image', image)
    //
    //   const features = plots.map( plot => {
    //     return {
    //       type: 'Feature',
    //       geometry: {
    //         type: 'Point',
    //         coordinates: [plot.entrance_point?.longitude || 0, plot.entrance_point?.latitude || 0]
    //       }
    //     }
    //   })
    //
    //   this.map.addSource('plots_center_source', {
    //     type: 'geojson',
    //     data: {
    //       type: 'FeatureCollection',
    //       features: features
    //     }
    //   })
    //
    //   this.map.addLayer({
    //     id: 'center',
    //     type: 'symbol',
    //     source: 'plots_center_source',
    //     layout: {
    //       'icon-image': 'plot-center-image',
    //       'icon-size': 1
    //     }
    //   })
    // })

  }

  loadProductiveSpaceMap(event) {
    const {mapElementId} = event.params

    if (mapElementId) {
      const {lat, lng} = this.mapCenterValue
      const marker = new mapbox.Marker()

      this.map = new mapbox.Map({
        container: mapElementId,
        style: "mapbox://styles/mapbox/satellite-streets-v12",
        zoom: 16,
        center: [lng, lat]
      })

      this.map.addControl(new mapbox.NavigationControl())

      this.map.on("load", (e) => this.drawProductiveSpacePerimeter())

      marker.setLngLat([lng, lat]).addTo(this.map)
    }
  }

  loadNonComplianceAnswerMap(event) {
    const {mapElementId} = event.params
    const {lng, lat} = this.mapCenterValue

    if (mapElementId) {
      this.map = new mapbox.Map({
        container: mapElementId,
        style: "mapbox://styles/mapbox/satellite-streets-v12",
        zoom: 16,
        center: [lng, lat]
      })

      this.map.addControl(new mapbox.NavigationControl())

      this.map.on("load", () => {
        const marker = new mapbox.Marker()
        marker.setLngLat([lng, lat]).addTo(this.map)
        this.drawPolygons(false)
      })

      // this.map.on("idle", () => {
      //   const marker = new mapbox.Marker()
      //   marker.setLngLat([lng, lat]).addTo(this.map)
      // })
    }
  }

  drawProductiveSpacePerimeter() {
    // console.log('entra', this.einValue)
    console.log(this.polygonsValue)
    if (this.polygonsValue && this.polygonsValue.length > 0) {
      const coordinates = this.polygonsValue.map(({
                                                    longitude,
                                                    latitude,
                                                    main
                                                  }) => !main && [longitude, latitude]).filter((el) => el)
      const entrance = this.polygonsValue.map(({longitude, latitude, main}) => main && ({
        latitude,
        longitude
      })).filter((el) => el).at(0)

      const feature = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: coordinates
        }
      }

      if (!this.map.getSource("perimeter")) {
        this.map.addSource("perimeter", {
          type: "geojson",
          data: feature
        })
      }

      if (!this.map.getLayer("productive-space-perimeter")) {
        this.map.addLayer({
          id: "productive-space-perimeter",
          type: "line",
          source: "perimeter",
          layout: {
            "line-join": "round", "line-cap": "round"
          },
          paint: {
            "line-color": "#FC9D03", "line-width": 4, "line-dasharray": [1, 2]
          }
        })
      }

      this.map.flyTo({
        center: entrance ? [entrance.longitude, entrance.latitude] : coordinates.at(0),
        essential: true
      })
    }
  }

  drawWaypoints() {
    if (this.inspectionValue) {
      const {waypoints} = this.inspectionValue || []
      const groupedCoordinates = Object.groupBy(waypoints, ({group_id}) => group_id)

      // const coordinates = waypoints?.map((waypoint) => [waypoint.longitude, waypoint.latitude])

      const featureCollection = {
        type: 'FeatureCollection',
        features: []
      }

      for (const key in groupedCoordinates) {
        const path = {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: groupedCoordinates[key].map(({longitude, latitude}) => [longitude, latitude])
          }
        }

        featureCollection.features.push(path)

        const startCoordinate = groupedCoordinates[key][0]

        const startPoint = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [startCoordinate.longitude, startCoordinate.latitude]
          },
          properties: {
            markerColor: 'green'
          }
        }

        featureCollection.features.push(startPoint)


        const endCoordinate = groupedCoordinates[key].at(-1)

        const endPoint = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [endCoordinate.longitude, endCoordinate.latitude]
          },
          properties: {
            markerColor: 'red'
          }
        }

        featureCollection.features.push(endPoint)
      }

      if (!this.map.getSource("route")) {
        this.map.addSource("route", {
          type: "geojson",
          data: featureCollection
        })
      }

      if (!this.map.getLayer("route")) {
        this.map.addLayer({
          id: "route",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round", "line-cap": "round"
          },
          paint: {
            "line-color": "#91DAFA", "line-width": 4, "line-dasharray": [1, 2],
          }
        })
      }

      this.map.addLayer({
        'id': 'points',
        'type': 'circle',
        'source': 'route',
        'paint': {
          'circle-radius': 6,
          'circle-color': ['get', 'markerColor']
        },
        'filter': ['==', '$type', 'Point']
      })

      // if (!this.map.getSource("init-route")) {
      //   this.map.addSource("init-route", {
      //     type: "geojson",
      //     data: {
      //       type: "FeatureCollection",
      //       features: [{
      //         type: "Feature",
      //         geometry: {
      //           type: "Point",
      //           coordinates: coordinates[0]
      //         }
      //       }]
      //     }
      //   })
      // }

      // if (!this.map.getLayer("init-route-point")) {
      //   this.map.addLayer(
      //     {
      //       id: 'init-route-point',
      //       type: 'circle',
      //       source: 'init-route',
      //       paint: {
      //         "circle-radius": {
      //           base: 1.75,
      //           stops: [
      //             [12, 2],
      //             [22, 40]
      //           ]
      //         },
      //         "circle-color": "green"
      //       }
      //     }
      //   );
      // }

      // if (!this.map.getSource("end-route")) {
      //   this.map.addSource("end-route", {
      //     type: "geojson",
      //     data: {
      //       type: "FeatureCollection",
      //       features: [{
      //         type: "Feature",
      //         geometry: {
      //           type: "Point",
      //           coordinates: coordinates.at(-1)
      //         }
      //       }]
      //     }
      //   })
      // }

      // if (!this.map.getLayer("end-route-point")) {
      //   this.map.addLayer(
      //     {
      //       id: 'end-route-point',
      //       type: 'circle',
      //       source: 'end-route',
      //       paint: {
      //         "circle-radius": {
      //           base: 1.75,
      //           stops: [
      //             [12, 2],
      //             [22, 40]
      //           ]
      //         },
      //         "circle-color": "red"
      //       }
      //     }
      //   );
      // }
    }
  }

  drawPolygons(fitBounds = true) {
    if (this.inspectionValue) {
      const bounds = this.getBounds()

      if (bounds.length === 0) return

      if (fitBounds) {
        const circle = turf.circle(bounds.flat(), 0.2, {units: "kilometers"})
        this.map.fitBounds(turf.bbox(circle))
      }

      this.inspectionValue.embedded_productive_units.forEach((unit) => {
        if (unit && unit.embedded_productive_spaces) {
          unit.embedded_productive_spaces.forEach((plot, index) => {
            let initPoint, endPoint
            let c = plot.bounds.map((bound) => [bound.longitude, bound.latitude])

            const points = plot.bounds.map((bound, index) => {
              return {
                type: "Feature",
                properties: {title: `${index}`},
                geometry: {
                  type: "Point",
                  coordinates: [bound.longitude, bound.latitude]
                }
              }
            })

            if (plot.bounds.length) {
              initPoint = [plot.bounds[0].longitude, plot.bounds[0].latitude]
              // endPoint = [plot.bounds[plot.bounds.length - 1].longitude, plot.bounds[plot.bounds.length - 1].latitude]
              c.push(initPoint)
            }

            // const feature = {
            //   type: "Feature",
            //   properties: {},
            //   geometry: {
            //     type: "Polygon",
            //     coordinates: [c]
            //   }
            // }

            const feature = {
              type: "Feature",
              properties: {},
              geometry: {
                type: "LineString",
                coordinates: c
              }
            }

            if (!this.map.getSource(`inspections_productive_units_productive_space_${plot._id}`)) {
              this.map.addSource(`inspections_productive_units_productive_space_${plot._id}`, {
                type: "geojson",
                data: {type: "FeatureCollection", features: [feature]}
              })
            }

            if (!this.map.getLayer(`inspections_productive_units_productive_space_${plot._id}`)) {
              this.map.addLayer({
                id: `inspections_productive_units_productive_space_${plot._id}`,
                type: "fill",
                // type: "line",
                source: `inspections_productive_units_productive_space_${plot._id}`,
                layout: {
                  visibility: "visible",
                  // "line-join": "round", "line-cap": "round"
                },
                paint: {
                  "fill-color": "yellow", "fill-opacity": 0.4,
                  // "line-color": "yellow", "line-width": 2
                }
              })
            }

            if (!this.map.getLayer(`inspections_productive_units_productive_space_${plot._id}_limits`)) {
              this.map.addLayer({
                id: `inspections_productive_units_productive_space_${plot._id}_limits`,
                // type: "fill",
                type: "line",
                source: `inspections_productive_units_productive_space_${plot._id}`,
                layout: {
                  visibility: "visible",
                  "line-join": "round", "line-cap": "round"
                },
                paint: {
                  // "fill-color": "yellow", "fill-opacity": 0.4,
                  "line-color": "yellow", "line-width": 2
                }
              })
            }
          })
        }
      })
    }
  }

  drawItems(collection) {
    let markers = []

    if (collection.length) {
      collection.forEach((item) => {
        // console.log(item.itemable_type)
        const kind = item.itemable_type.replace(/[A-Z]/g,
          (l, i) => i === 0 ? l.toLowerCase() : `_${l.toLowerCase()}`
        )

        for (const variety of item[`${kind}_details`]) {
          if (!variety.coordinates) continue

          const {latitude, longitude} = variety.coordinates
          const popup = new mapbox.Popup({closeOnClick: true}).setText(`${item.name} - ${variety.variety_name}`)
          const marker = new mapbox.Marker()
          const markerElement = marker.getElement()

          markerElement.classList.add(`ps-${variety.productive_space_realm_id}`)
          marker.element = markerElement
          marker.setLngLat([longitude, latitude]).setPopup(popup).addTo(this.map)
          marker.element.addEventListener("click", (e) => this.toggleItem(e, variety))
          markers.push(marker)
          popup.on("open", (e) => this.toggleItem(e, variety))
          popup.on("close", (e) => this.toggleItem(e))
        }
      })
    }
  }

  toggleProductiveSpace(event) {
    const selected = `.ps-${event.target.value}`
    const elements = document.querySelectorAll(selected)

    const visibility = this.map.getLayoutProperty(event.target.id, "visibility") || "visible"

    this.map.setLayoutProperty(event.target.id, "visibility", visibility === "visible" ? "none" : "visible")

    if (this.map.getLayer(`${event.target.id}_limits`))
      this.map.setLayoutProperty(`${event.target.id}_limits`, "visibility", visibility === "visible" ? "none" : "visible")

    if (elements.length) {
      elements.forEach((element) => {
        if (element.classList.contains('hidden')) element.classList.remove('hidden')
        else element.classList.add('hidden')
      })
    }
  }

  toggleItem(event, item = null) {
    if (event instanceof PointerEvent) event.preventDefault()

    const section = document.querySelector("#items")

    if (section) {
      const collection = section.querySelectorAll(".item")
      // const collection = section.querySelectorAll(`.ps-${item.productive_space_realm_id}`)

      if (collection.length) {
        collection.forEach((element) => {
          const figcaption = element.querySelector("figcaption")
          figcaption.classList.remove("fw-bold", "text-decoration-underline", "text-danger")
          figcaption.parentNode.parentNode.classList.remove("border", "border-3", "border-danger", "rounded", "p-2")
        })
      }

      if (item) {
        const variety = document.querySelector(`#variety-${item._id}`)

        if (variety) {
          variety.classList.add("border", "border-3", "border-danger", "rounded", "p-2")
          const caption = variety.querySelector('figcaption')
          if (caption) caption.classList.add("fw-bold", "text-decoration-underline", "text-danger")
        }
      }
    }
  }

  toggleWaypoints(event) {
    const {checked} = event.target
    const layerNames = ["route", "init-route-point", "end-route-point"]

    layerNames.forEach((layerName) => {
      this.map.setLayoutProperty(layerName, "visibility", checked ? "visible" : "none")
    })
  }

  getBounds() {
    let bounds = []

    this.inspectionValue.embedded_productive_units.forEach((unit) => {
      if (unit && unit.embedded_productive_spaces) {
        unit.embedded_productive_spaces.forEach((plot) => {
          if (plot.bounds.length) {
            plot.bounds.forEach((bound) => {
              bounds.push([bound.longitude, bound.latitude])
            })
            // bounds.push([plot.bounds[0].longitude, plot.bounds[0].latitude])
          }
        })
      }
    })

    return bounds
  }

  getTotalDistance(event) {
    event.preventDefault()
    const {bounds, elementId} = event.params
    const element = document.getElementById(elementId)

    if (element) {
      if (bounds && bounds.length) {
        const groupedBounds = Object.groupBy(bounds, ({group_id}) => group_id)
        let distance = 0

        for (const key in groupedBounds) {
          const current = groupedBounds[key]

          for (let i = 0; i < current.length - 1; i++) {
            distance += getDistance(current[i], current[i + 1], 0.01)
          }
        }

        // let result = bounds.reduce((acc, curr, index) => {
        //   if (index + 1 !== bounds.length) return acc + getDistance(curr, bounds[index + 1], 0.01)
        //   else return acc
        // }, 0)

        // this.element.innerHTML = `${result} m.`
        element.innerHTML = `${distance.toFixed(2)}`
      }
    }
  }

  readKml(event) {
    event.preventDefault()

    const file = event.target.files[0]
    const reader = new FileReader()

    reader.onload = (e) => {
      const kmlData = e.target.result
      this.parseKml(kmlData)
    }

    reader.readAsText(file)
  }

  async parseKml(kml) {
    const parser = new DOMParser();
    const kmlDocument = parser.parseFromString(kml, "text/xml")
    const elements = kmlDocument.getElementsByTagName("coordinates")
    let polygons = []

    for (const element of elements) {
      const coordinates = element.textContent?.split(" ")?.filter(a => !!a)
      const result = coordinates.map((c) => c.split(",").map((el) => Number(el.trim())))
      const polygon = result.filter((el) => el.length >= 2)
      polygons.push(polygon)
    }

    await this.drawTemporalPolygon(polygons)

    const area = polygons.reduce((acc, curr, index) => {
      return acc + getAreaOfPolygon(curr)
    }, 0)

    const areaElement = document.querySelector("input[id*=_area]")
    if (areaElement) areaElement.value = (area / 10000).toFixed(2)

    // const formattedPolygon = polygons[0].map((point) => {
    //   return { latitude: point[0], longitude: point[1], altitude: point[2] }
    // })
    // const centerOfBounds = getCenterOfBounds(formattedPolygon)
    // const marker = new mapbox.Marker()
    // marker.setLngLat(centerOfBounds).addTo(this.map)
  }

  drawTemporalPolygon(polygons) {
    const features = []
    // const firstPosition = polygons.at(0)
    const center = getCenter(polygons.flat())

    for (const polygon of polygons) {
      const coordinates = polygon?.reduce((acc, current) => {
        if (current?.[0] && current?.[1]) acc.push([current[0], current[1]])
        return acc
      }, [])

      const feature = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: coordinates
        }
      }

      features.push(feature)
    }

    const source = this.map.getSource("temp-perimeter")

    if (!source) {
      this.map.addSource("temp-perimeter", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: features
        }
      })
    } else {
      source.setData({type: "FeatureCollection", features})
    }

    if (!this.map.getLayer("temp-productive-space-perimeter")) {
      this.map.addLayer({
        id: "temp-productive-space-perimeter",
        type: "line",
        source: "temp-perimeter",
        layout: {
          "line-join": "round", "line-cap": "round"
        },
        paint: {
          "line-color": "#fc9d03", "line-width": 4, "line-dasharray": [1, 2]
        }
      })
    }

    const marker = new mapbox.Marker()
    // marker.setLngLat([firstPosition[0]?.[0], firstPosition[0]?.[1]]).addTo(this.map)
    marker.setLngLat([center.longitude, center.latitude]).addTo(this.map)

    const mainLatitudeInput = document.querySelector("input[id*=_main_latitude]")
    const mainLongitudeInput = document.querySelector("input[id*=_main_longitude]")

    if (mainLatitudeInput && mainLongitudeInput) {
      // Center
      // mainLatitudeInput.value = firstPosition[0]?.[1]
      // mainLongitudeInput.value = firstPosition[0]?.[0]
      mainLatitudeInput.value = center.latitude
      mainLongitudeInput.value = center.longitude
    }

    this.map.flyTo({
      // center: [firstPosition[0]?.[0], firstPosition[0]?.[1]],
      center: [center.longitude, center.latitude],
      essential: true
    })
  }
}
