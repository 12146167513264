import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="productive-spaces-item"
export default class extends Controller {
  static targets = ["areaField"]
  connect() {
    console.log("Productive spaces items controller connected")
  }

  toggleFullAreaCovered($event) {
    if ($event.target.checked) {
      this.areaFieldTarget.disabled = true
    } else {
      this.areaFieldTarget.disabled = false
      this.areaFieldTarget.value = $event.target.dataset.psArea
    }
  }
}
