import {Controller} from "@hotwired/stimulus"
import {FetchRequest, post} from "@rails/request.js"

// Connects to data-controller="inspections"
export default class extends Controller {
  static targets = ["evaluationRadioButtonGroup", "successLabel", "warningLabel", "dangerLabel"]
  connect() {
    this.token = document.querySelector('meta[name="csrf-token"]').content;
  }

  disconnect() {
    super.disconnect()
  }

  async consolidate(event) {
    const {params} = event
    const originalText = event.target.innerText
    event.target.disabled = true
    event.target.innerText = event.target.dataset.consolidating
    const {inspectionId, locale} = params
    const response = await post(`/${locale}/inspections/${inspectionId}/consolidate`, {responseKind: 'turbo-stream'})
    const { text, ok } = await response

    event.target.disabled = false
    event.target.innerText = originalText
  }

  toggleNorm(event) {
    event.preventDefault()

    const {nextElementSibling, checked} = event.target
    const {lastElementChild} = nextElementSibling

    if (checked) {
      lastElementChild.classList.remove("bi-x", "text-danger")
      lastElementChild.classList.add("bi-check", "text-success")
    } else {
      lastElementChild.classList.remove("bi-check", "text-success")
      lastElementChild.classList.add("bi-x", "text-danger")
    }
  }

  toggleRow(event) {
    event.preventDefault()

    const {checked} = event.target
    const elements = this.element.parentElement.querySelectorAll('.inspections-group-entity-checkbox')

    if (elements.length) {
      elements.forEach((element) => {
        element.disabled = !checked
      })
    }
  }

  toggleColumn(event) {
    event.preventDefault()

    const {params} = event
    console.log({params})
    const {checked} = event.target
    const elements = document.querySelectorAll(`input.${params.elementId}`)

    if (elements.length) {
      elements.forEach((element) => {
        // if (!element.disabled) element.checked = checked
        const changeEvent = new Event('change')
        element.checked = checked
        element.dispatchEvent(changeEvent)
      })
    }
  }

  toggleCaret(event) {
    event.preventDefault()
    const {target} = event
    const {lastElementChild} = target
    if (lastElementChild) {
      if (lastElementChild.classList.contains("bi-caret-right-fill") || lastElementChild.classList.contains("bi-caret-down-fill")) {
        lastElementChild.classList.toggle("bi-caret-right-fill")
        lastElementChild.classList.toggle("bi-caret-down-fill")
      } else {
        lastElementChild.classList.toggle("bi-caret-right")
        lastElementChild.classList.toggle("bi-caret-down")
      }
    } else {
      if (target.classList.contains("bi-caret-right-fill") || target.classList.contains("bi-caret-down-fill")) {
        target.classList.toggle("bi-caret-right-fill")
        target.classList.toggle("bi-caret-down-fill")
      } else {
        target.classList.toggle("bi-caret-right")
        target.classList.toggle("bi-caret-down")
      }
    }
  }

  setEvaluation($event) {
    const label = $event.target.closest("label")
    if (!label) {
      return;
    }

    // elimino los estilos de seleccionado a todos
    this.successLabelTarget.classList.remove("btn-success")
    this.warningLabelTarget.classList.remove("btn-warning")
    this.dangerLabelTarget.classList.remove("btn-danger")

    this.successLabelTarget.classList.add("btn-outline-success")
    this.warningLabelTarget.classList.add("btn-outline-warning")
    this.dangerLabelTarget.classList.add("btn-outline-danger")

    this.warningLabelTarget.querySelectorAll("i").forEach(icon => {
      icon.classList.remove("text-white")
    })

    if (label.classList.contains("btn-outline-success") || label.classList.contains("btn-success")) {
      this.successLabelTarget.classList.remove("btn-outline-success")
      label.classList.add("btn-success");
    }

    if (label.classList.contains("btn-outline-warning") || label.classList.contains("btn-warning")) {
      this.warningLabelTarget.classList.remove("btn-outline-warning")
      label.classList.add("btn-warning");
      this.warningLabelTarget.querySelectorAll("i").forEach(icon => {
        icon.classList.add("text-white")
      })
    }

    if (label.classList.contains("btn-outline-danger") || label.classList.contains("btn-danger")) {
      this.dangerLabelTarget.classList.remove("btn-outline-danger")
      label.classList.add("btn-danger");
    }
  }
}
