import { Controller } from "@hotwired/stimulus"
import { put } from "@rails/request.js"

// Connects to data-controller="bundled-proposal-review"
export default class extends Controller {
  static targets= ['reviewedItemsSize']
  connect() {
  }

  async toggle(event) {
    const { url } = event.currentTarget.dataset
    try {
      const submitButton = document.getElementById("validate-list")

      if (!event.target.checked) {
        submitButton.classList.add('disabled')
      }

      const options = { headers: {
        'Accept': 'application/json'
      },
      responseKind: "application/json",
        body: { reviewed: event.target.checked }
      }

      const response = await put(url, options)
      const { json, ok } = await response

      const { all_reviewed, reviewed_items } = await json

      this.reviewedItemsSizeTarget.innerHTML = reviewed_items

      if (all_reviewed) {
        submitButton.classList.remove('disabled')
      } else {
        submitButton.classList.add('disabled')
      }

    } catch(e) {
      console.error(e)
    }
    console.log(url)
  }

  async toggleAll(event) {
    try {
      const checkboxes = document.querySelectorAll('input[type="checkbox"]')
      const submitButton = document.getElementById("validate-list")
      checkboxes.forEach(checkbox => checkbox.checked = event.target.checked)

      if (!event.target.checked) {
        submitButton.classList.add('disabled')
      }

      const { url }  = event.currentTarget.dataset
      const options = { headers: {
          'Accept': 'application/json'
        },
        responseKind: "application/json",
        body: { reviewed: event.target.checked }
      }

      const response = await put(url, options)
      const { json, ok } = await response
      const { reviewed_items } = await json

      this.reviewedItemsSizeTarget.innerHTML = reviewed_items

      if (ok && event.target.checked) {
        submitButton.classList.remove('disabled')
      }
    } catch(e) {
      console.error(e)
    }
  }
}
