import { Controller } from "@hotwired/stimulus"
import {post} from "@rails/request.js";

// Connects to data-controller="standalone-proposal"
export default class extends Controller {
  connect() {
  }

  async toggleProductiveSpaceProposal($event) {
    const { url, plot_realm_id } = $event.target.dataset
    const response = await post(url, { params: { standalone_proposals_norm: { plot_realm_id, checked: $event.target.checked } }, responseKind: 'turbo-stream'})
  }

  disable($event) {
    setTimeout(() => {
      $event.target.disabled = true
    }, 1)
  }
}
