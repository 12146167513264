import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="files"
export default class extends Controller {
  static targets = ["input", "files"]

  add(event) {
    // Grab some references for later
    const originalInput = event.target
    const originalParent = originalInput.parentNode

    // Create an element that contains our input element
    // const selectedFile = document.createElement("div")
    // selectedFile.className = "selected-file"
    // selectedFile.append(originalInput)

    // Create label (the visible part of the new input element) with the name of
    // the selected file.
    // let labelNode = document.createElement("label");
    // let textElement = document.createTextNode(originalInput.files[0].name);
    // labelNode.appendChild(textElement);
    // selectedFile.appendChild(labelNode);

    // Add the selected file to the list of selected files
    this.filesTarget.append(originalInput)

    // Create a new input field to use going forward
    const newInput = originalInput.cloneNode()

    // Clear the filelist - some browsers maintain the filelist when cloning,
    // others don't
    newInput.value = ""

    // Add it to the DOM where the original input was
    originalParent.append(newInput)
  }

  preview(event) {
    const files = event.target.files
    const parent = event.target.parentNode

    for (let i = 0; i < files.length; i++) {
      let reader = new FileReader()

      reader.onload = function (e) {
        parent.appendChild(files[i])
      }

      reader.readAsDataURL(files[i])
    }
  }

  async downloadFile($event) {
    const button = $event.currentTarget
    if (button.dataset.disabledOnLoad === "true") {
      button.disabled = true
      button.classList.add("disabled")
    }

    try {
      const response = await fetch(button.dataset.url)
      const content = await response.blob()

      const contentDisposition = response.headers.get("Content-Disposition");
      let filename = "downloaded_file";

      if (contentDisposition && contentDisposition.includes("filename=")) {
        filename = contentDisposition.split("filename=")[1].split(";")[0].replace(/['"]/g, "");
      }

      const blob = new Blob([content], { type: content.type })
      const fileUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = fileUrl
      link.target = '_blank'
      link.download = filename
      document.body.appendChild(link)
      link.click();

      document.body.removeChild(link)
    } catch(e) {
      console.error(e)
    }

    button.disabled = false
    button.classList.remove("disabled")
  }
}
