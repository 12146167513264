import { Controller } from "@hotwired/stimulus"
import toastr from "toastr";

// Connects to data-controller="notifications"
export default class extends Controller {
  static values = { type: String, message: String }
  connect() {
    console.log('conecta?')
    if (this.messageValue.trim() !== "") {
      toastr.options = {
        closeButton: true,
        progressBar: true,
        positionClass: "toast-top-center",
        timeOut: 20000
      };

      if (this.typeValue === "success") {
        toastr.success(this.messageValue);
      } else if (this.typeValue === "error" || this.typeValue === "alert") {
        toastr.error(this.messageValue);
      } else if (this.typeValue === "warning") {
        toastr.warning(this.messageValue);
      } else {
        toastr.info(this.messageValue);
      }
    }
  }
}
